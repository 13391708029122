<template>
  <b-container fluid>
    <b-container>
      <h1>Konto online</h1><br/>
      <h2>Masz do wykorzystania: <strong>{{ points === null ? '.....' : points }} pkt.</strong></h2><br/>
      Posiadane karty (numery):
      <ul>
        <li v-for="(item, index) in cardsList" :key="index">{{ item.nr }}</li>
      </ul>
      <br/>
      <div v-if="cardsList.length === 0">Nie posiadasz żadnych aktywnych kart :(</div>
      <div>
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Moje dane">
              <user-data :user="user" :card_title="`Edycja danych`"/>
            </b-tab>
            <b-tab title="Moje zakupy">
              <transaction-list :user="user" :card_title="`Historia zakupów`"/>
            </b-tab>
            <b-tab title="Moje nagrody">
              <my-awards :card_title="`Historia nagród`" :user="user"/>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>

    </b-container>
  </b-container>
</template>

<script>
// komponenty
import UserData from '@/components/User/UserData.vue';
import TransactionList from '@/components/User/TransactionList.vue';
import MyAwards from '@/components/User/MyAwards.vue';

// services
import UserService from '@/_services/user.service';

const userService = new UserService();

export default {
  name: 'user',
  components: {
    UserData, TransactionList, MyAwards,
  },
  data() {
    return {
      cardsList: [],
      points: null,
      loading: false,
      user: {
        token: null,
        birth_date: null,
        cell_phone: null,
        sex: null,
        first_name: null,
        last_name: null,
        street: null,
        house_nr: null,
        local_nr: null,
        city: null,
        post_code: null,
        shop_id: null,
        email: null,
        marketing: 0,
        marketing_ext: 0,
        approvals: {},
        tos: 1,
      },
    };
  },
  async beforeCreate() {
    this.$store.state.loading = true;

    await userService.getUserData().then((response) => {
      Object.keys(response).forEach((key) => {
        if (typeof this.user[key] !== 'undefined') {
          this.user[key] = response[key];
          if (response[key] === null) {
            this.user[key] = '';
          }
        }
        this.cardsList = response.cards;
        this.$store.state.loading = false;
      });

      this.points = response.available_points ?? 0;
    });
  },

};

</script>
