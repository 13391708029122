<template>
  <div>
    <b-card-text>{{card_title}}</b-card-text>
    <b-table  id="transaction-list" striped hover
              :per-page="perPage"
              :current-page="currentPage"
              :items="transaction_list"  small></b-table>
    <div v-if="transaction_list.length === 0">
      Brak wyników :(
    </div>
    <b-pagination
      align="center"
      pills
      v-model="currentPage"
      :total-rows="transaction_list.length"
      :per-page="perPage"
      :limit="10"
      aria-controls="transaction-list"
    ></b-pagination>

  </div>
</template>

<script>
import SimpleFilter from '@/components/SimpleFilter.vue';
import UserService from '@/_services/user.service';

const userService = new UserService();
export default {
  name: 'UserData',
  components: { SimpleFilter },
  props: {
    user: Object,
    card_title: String,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      transaction_list: [],
      loading: false,
    };
  },
  methods: {
    search() {
      userService.getTransactions()
        .then((result) => {
          this.$store.state.loading = false;
          this.transaction_list = [];
          Object.values(result.data).forEach((transaction) => {
            const element = [];
            element.ID = transaction.transaction_id;
            element['Miejscowość'] = `${transaction.shop.city}`;
            element['Kwota w programie'] = transaction.value;
            element.Punkty = transaction.summary_points;
            element.Data = transaction.date;
            this.transaction_list.push(element);
          });
        });
    },
  },
  created() {
    this.search();
  },
};
</script>
