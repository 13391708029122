<template>
  <div>
    <b-card-text>{{ card_title }}</b-card-text>
    <b-form>
      <b-form-group id="birth-date" label="Data urodzenia" label-for="birth_date">
        <b-form-input readonly
                      disabled
                      :type="`date`"
                      v-model="user.birth_date"
                      aria-describedby="birth-date-feedback"></b-form-input>
      </b-form-group>

      <b-form-group id="sex" label="Płeć" label-for="sex">

        <b-form-select readonly disabled
                       v-model="user.sex"
                       @input="$v.user.sex.$touch()"
                       :state="(!$v.user.sex.$error && validation.sex == null) && null"
                       :options="sex" class="mt-3"
                       aria-describedby="sex-feedback"></b-form-select>
      </b-form-group>

      <b-form-group id="first-name" label="Numer telefonu" label-for="cell_phone">
        <b-form-input v-model="user.cell_phone"
                      @input="$v.user.cell_phone.$touch()"
                      @change="resetApiValidation('first_name')"
                      :state="(!$v.user.cell_phone.$error && validation.cell_phone == null) && null"
                      aria-describedby="first_name-feedback"></b-form-input>
        <b-form-invalid-feedback id="first_name-feedback">
          <div class="error" v-if="!$v.user.cell_phone.required">Pole wymagane</div>
          {{ validation.cell_phone }}
        </b-form-invalid-feedback>
      </b-form-group>


      <b-form-group id="first-name" label="Imię" label-for="first_name">
        <b-form-input v-model="user.first_name"
                      @input="$v.user.first_name.$touch()"
                      @change="resetApiValidation('first_name')"
                      :state="(!$v.user.first_name.$error && validation.first_name == null) && null"
                      aria-describedby="first_name-feedback"></b-form-input>
        <b-form-invalid-feedback id="first_name-feedback">
          <div class="error" v-if="!$v.user.first_name.required">Pole wymagane</div>
          {{ validation.first_name }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="last-name" label="Nazwisko" label-for="lastname">
        <b-form-input v-model="user.last_name"
                      @input="$v.user.last_name.$touch()"
                      @change="resetApiValidation('last_name')"
                      :state="(!$v.user.last_name.$error && validation.last_name == null) && null"
                      aria-describedby="first_name-feedback"></b-form-input>
        <b-form-invalid-feedback id="first_name-feedback">
          <div class="error" v-if="!$v.user.last_name.required">Pole wymagane</div>
          {{ validation.last_name }}
        </b-form-invalid-feedback>
      </b-form-group>


      <b-form-group id="street" label="Ulica" label-for="street">
        <b-form-input v-model="user.street"
                      @change="resetApiValidation('street')"
                      :state="(validation.street == null) && null"
                      aria-describedby="street-feedback"></b-form-input>
        <b-form-invalid-feedback id="street-feedback">
          {{ validation.street }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="house_nr" label="Nr domu" label-for="house_nr">
        <b-form-input v-model="user.house_nr"
                      @input="$v.user.house_nr.$touch()"
                      @change="resetApiValidation('house_nr')"
                      :state="(!$v.user.house_nr.$error && validation.house_nr == null) && null"
                      aria-describedby="house_nr-feedback"></b-form-input>
        <b-form-invalid-feedback id="house_nr-feedback">
          <div class="error" v-if="!$v.user.house_nr.required">Pole wymagane</div>
          {{ validation.house_nr }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="localnr" label="Nr lokalu" label-for="local_nr">
        <b-form-input v-model="user.local_nr"
                      @change="resetApiValidation('local_nr')"
                      :state="(validation.local_nr == null) && null"
                      aria-describedby="local_nr-feedback"></b-form-input>
        <b-form-invalid-feedback id="local_nr-feedback">
          {{ validation.local_nr }}
        </b-form-invalid-feedback>
      </b-form-group>


      <b-form-group id="city" label="Miejscowość" label-for="city">
        <b-form-input v-model="user.city"
                      @input="$v.user.city.$touch()"
                      @change="resetApiValidation('city')"
                      :state="(!$v.user.city.$error && validation.city == null) && null"
                      aria-describedby="city-feedback"></b-form-input>
        <b-form-invalid-feedback id="city-feedback">
          <div class="error" v-if="!$v.user.city.required">Pole wymagane</div>
          {{ validation.city }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="post_code" label="Kod pocztowy" label-for="post_code">
        <b-form-input v-model="user.post_code"
                      @input="$v.user.post_code.$touch()"
                      @change="resetApiValidation('city')"
                      :state="(!$v.user.post_code.$error && validation.post_code == null) && null"
                      aria-describedby="postcode-feedback"></b-form-input>
        <b-form-invalid-feedback id="postcode-feedback">
          <div class="error" v-if="!$v.user.post_code.required">Pole wymagane</div>
          <div class="error" v-if="!$v.user.post_code.minLength">Błędna wartość - zbyt krótki kod
            pocztowy
          </div>
          <div class="error" v-if="!$v.user.post_code.maxLength">Błędna wartość - zbyt długi kod
            pocztowy
          </div>
          {{ validation.post_code }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="email" label="Email" label-for="email">
        <b-form-input v-model="user.email"
                      @input="$v.user.email.$touch()"
                      @change="resetApiValidation('email')"
                      :state="(!$v.user.email.$error && validation.email == null) && null"
                      aria-describedby="email-feedback"></b-form-input>
        <b-form-invalid-feedback id="email-feedback">
          <div class="error" v-if="!$v.user.email.email">To nie wygląda na adres e-mail ( ͡° ͜ʖ͡°)
          </div>
          {{ validation.email }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-if="$store.state.settings.app_texts.approvals_prepend_text">
        <div v-html="$store.state.settings.app_texts.approvals_prepend_text"></div>
        <br/>
      </template>

      <template v-if="$store.state.settings.app_texts.approvals_marketing">
        <b-form-checkbox
          switch
          id="user_marketing"
          v-model="user.approvals.marketing"
          v-bind:true-value=true
          v-bind:false-value=false
        >
          <div v-html="$store.state.settings.app_texts.approvals_marketing"></div>
        </b-form-checkbox>
        <br/>
      </template>

      <template v-if="$store.state.settings.app_texts.approvals_marketing_sms">
        <b-form-checkbox
          switch
          id="marketing_sms"
          v-model="user.approvals.marketing_sms"
          v-bind:true-value=true
          v-bind:false-value=false

        >
          <div v-html="$store.state.settings.app_texts.approvals_marketing_sms"></div>
        </b-form-checkbox>
        <br/>
      </template>
      <template v-if="$store.state.settings.app_texts.approvals_marketing_mail">
        <b-form-checkbox
          switch
          id="marketing_mail"
          v-model="user.approvals.marketing_mail"
          v-bind:true-value=true
          v-bind:false-value=false

        >
          <div v-html="$store.state.settings.app_texts.approvals_marketing_mail"></div>
        </b-form-checkbox>
        <br/>
      </template>
      <template v-if="$store.state.settings.app_texts.approvals_marketing_phone">
        <b-form-checkbox
          switch
          id="marketing_phone"
          v-model="user.approvals.marketing_phone"
          v-bind:true-value=true
          v-bind:false-value=false

        >
          <div v-html="$store.state.settings.app_texts.approvals_marketing_phone"></div>
        </b-form-checkbox>
        <br/>
      </template>

      <template v-if="$store.state.settings.app_texts.approvals_profiling">
        <b-form-checkbox
          switch
          id="profiling"
          v-model="user.approvals.profiling"
          v-bind:true-value=true
          v-bind:false-value=false

        >
          <div v-html="$store.state.settings.app_texts.approvals_profiling"></div>
        </b-form-checkbox>
        <br/>
      </template>

      <b-button variant="primary" @click="updateUser" :disabled="loading" class="float-right">
        <b-spinner type="grow" label="Spinning" small v-if="loading"></b-spinner>
        Zapisz dane
      </b-button>
      <div class="clearfix"></div>
    </b-form>
  </div>
</template>

<script>
import {
  required, between, email, minLength, maxLength,
} from 'vuelidate/lib/validators';

// services
import UserService from '@/_services/user.service';
import NotifyService from '@/_services/notify';
import rules from '@/_helpers/rules';

const userService = new UserService();
const notify = new NotifyService();

export default {
  name: 'UserData',
  props: {
    user: Object,
    card_title: String,
  },
  data() {
    return {
      rules: null,
      sex: [],
      validation: {
        nr: null,
        token: null,
        birth_date: null,
        cell_phone: null,
        house_nr: null,
        local_nr: null,
        street: null,
        sex: null,
        first_name: null,
        last_name: null,
        approvals: {
          marketing: 0,
          marketing_sms: 0,
          marketing_mail: 0,
          marketing_phone: 0,
          profiling: 0,
        }
      },
      loading: false,
    };
  },
  created() {
    this.sex = userService.getSexOptions();
    this.rules = rules.RULES;
  },
  mounted() {
    this.mapApprovals();
  },
  methods: {
    updateUser() {
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        return;
      }
      userService.changeData(this.user)
        .then((response) => {
          if (typeof response.data === 'object') {
            notify.success('Sukces!', 'Twoje dane zostały zmienione');
          }
        })
        .catch((error) => {
          Object.keys(error.response.data.errors)
            .forEach((key) => {
              const [message] = error.response.data.errors[key];
              this.validation[key] = message;
            });
        });
    },
    resetApiValidation(key) {
      this.validation[key] = null;
    },
    mapApprovals(){
      setInterval(() => {
        Object.keys(this.user.approvals).forEach((key) => {
          console.log(key);
          this.$set(this.user.approvals, key, !!this.user.approvals[key]);
        });
      }, 1000)
    }
  },

  validations: {
    user: {
      birth_date: {
        required,
      },
      sex: {
        between: between(1, 2),
      },
      cell_phone: {required},
      first_name: {required},
      last_name: {required},
      city: {required},
      post_code: {required, minLength: minLength(6), maxLength: maxLength(6)},
      house_nr: {required},
      email: {
        email,
      },
    },
  },
};
</script>
