<template>
  <div>
    <b-form inline>
        <label class="mr-sm-2" for="inline-form-custom-select-pref" v-if="filters.year.visible">Rok</label>
        <b-form-select v-if="filters.year.visible"
          class="mb-2 mr-sm-2 mb-sm-0"
          :value="``"
          v-model="filters.year.value"
          :options="years"
          id="inline-form-custom-select-pref"
        >
          <template v-slot:first>
            <option :value="``">Wszystko</option>
          </template>
        </b-form-select>
      <label class="mr-sm-2" for="inline-form-custom-select-pref" v-if="filters.month.visible">Miesiąc</label>
      <b-form-select v-if="filters.month.visible"
        class="mb-2 mr-sm-2 mb-sm-0"
        :value="``"
        v-model="filters.month.value"
        :options="months"
        id="inline-form-custom-select-pref"
      >
      </b-form-select>
      <b-button variant="primary" @click="searchInit" :disabled="searching">Szukaj</b-button>
    </b-form>
    <hr/>
  </div>
</template>

<script>
export default {
  name: 'SimpleFilter',
  props: {
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      years: [],
      months: [
        { value: '', text: 'Wszystkie' },
        { value: '1', text: 'Styczeń' },
        { value: '2', text: 'Luty' },
        { value: '3', text: 'Marzec' },
        { value: '4', text: 'Kwiecień' },
        { value: '5', text: 'Maj' },
        { value: '6', text: 'Czerwiec' },
        { value: '7', text: 'Lipiec' },
        { value: '8', text: 'Sierpień' },
        { value: '9', text: 'Wrzesień' },
        { value: '10', text: 'Październik' },
        { value: '11', text: 'Listopad' },
        { value: '12', text: 'Grudzień' },
      ],
      loading: false,
    };
  },
  methods: {
    fillYears(start = 2019) {
      let startYear = start;
      const currentYear = new Date().getFullYear();
      this.years = [];
      while (startYear <= currentYear) {
        this.years.push(startYear);
        startYear += 1;
      }
    },
    searchInit() {
      this.$store.state.loading = true;
      return this.$emit('search');
    },
  },
  created() {
    this.fillYears();
  },
  computed: {
    searching() {
      return this.$store.state.loading;
    },
  },
};
</script>
