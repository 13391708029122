/**
* plik z różnymi klauzulami itp. aby nie zmieniac w 100 miejscach.
 */

const RULES = {
  TOS: ` Oświadczam, że mam ukończone 18 lat, zapoznałam(em) się z treścią Regulaminu Programu,
      rozumiem i akceptuję jego postanowienia oraz zapoznałam(em) się z KLAUZULĄ INFORMACYJNĄ
      zamieszczoną poniżej.`,
  MARKETING: `Wyrażam zgodę na przetwarzanie moich danych osobowych, w celu wysyłania informacji handlowych
      drogą elektroniczną poprzez wykorzystanie środk&oacute;w komunikacji elektronicznej, w szczeg&oacute;lności
      w postaci przesyłania informacji handlowych, dotyczących produkt&oacute;w i usług, oferowanych
      przez Administratora lub Partner&oacute;w Programu, na podany przez mnie adres email lub numer
      telefonu kom&oacute;rkowego.`,
  MARKETING_EXT: `Wyrażam zgodę na przetwarzanie moich danych osobowych dla cel&oacute;w promocji i marketingu
      produkt&oacute;w lub usług oferowanych przez Administratora, jak i Partner&oacute;w
      Programu.`,
};

/**
 * eksport zmiennych
 */
export default {
  RULES,
};
