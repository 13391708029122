<template>
  <div>
    <b-card-text>{{ card_title }}</b-card-text>
    <b-table striped hover :items="award_list"></b-table>
    <div v-if="award_list.length === 0">
      Brak wyników :(
    </div>
  </div>
</template>

<script>

import UserService from '@/_services/user.service';
import SimpleFilter from '@/components/SimpleFilter.vue';

const userService = new UserService();

export default {
  name: 'MyAwards',
  components: {SimpleFilter},
  props: {
    user: Object,
    card_title: String,
  },
  data() {
    return {
      award_list: [],
      loading: false,
    };
  },
  methods: {
    search() {
      userService.getOrders()
        .then((result) => {
          this.$store.state.loading = false;
          this.award_list = [];
          Object.values(result.data).forEach((order) => {
            const element = [];
            element['Nazwa nagrody'] = order.title;
            element.Status = order.status_label;
            element['Koszt w punktach'] = order.points;
            element.Data = order.date;
            element['Data realizacji'] = order.confirm_date;
            this.award_list.push(element);
          });
        });
    },
  },
  created() {
    this.search();
  },
};
</script>
